import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { db, auth } from "../firebaseConfig";
import LessonModal from "./LessonModal";
import LessonDetailModal from "./LessonDetailModal";
import LessonSummaryModal from "./LessonSummaryModal";
import { useNavigate } from "react-router-dom";

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calendarKey, setCalendarKey] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = db.collection("lessons").onSnapshot((snapshot) => {
      const fetchedEvents = snapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        start: new Date(doc.data().start),
        extendedProps: doc.data().extendedProps,
      }));
      setEvents(fetchedEvents);
      setCalendarKey(prevKey => prevKey + 1);
    });
    return () => unsubscribe();
  }, []);

  const handleDateClick = (info) => {
    setSelectedDate(info.dateStr);
    setShowModal(true);
  };

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setShowSummaryModal(true);
  };

  const handleEdit = () => {
    setShowSummaryModal(false);
    setShowDetailModal(true);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <b>{eventInfo.event.title}</b>
        <br />
        <small>{new Date(eventInfo.event.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
      </div>
    );
  };

  const handleStatistics = () => {
    navigate('/statistics');
  };

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error("Çıkış yapılırken bir hata oluştu:", error);
      });
  };

  return (
    <div className="container mt-5">
      <FullCalendar
        key={calendarKey}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale="tr"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventDidMount={(info) => {
          const paymentStatus = info.event.extendedProps.paymentStatus;
          const lessonProcessed = info.event.extendedProps.lessonProcessed;

          // Ders hem işlendi hem de ödendiyse yeşil, sadece işlendi ise turuncu, aksi takdirde kırmızı
          if (lessonProcessed && paymentStatus) {
            info.el.style.backgroundColor = 'green';  // Hem işlendi hem ödendi
          } else if (lessonProcessed) {
            info.el.style.backgroundColor = 'orange'; // Sadece işlendi
          } else if (paymentStatus) {
            info.el.style.backgroundColor = 'blue'; // Sadece ödendi
          } else {
            info.el.style.backgroundColor = 'red'; // Ne işlendi ne ödendi
          }
          info.el.style.color = 'white';
        }}
        firstDay={1}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'statisticsButton,logoutButton',
        }}
        customButtons={{
          statisticsButton: {
            text: 'İstatistikler',
            click: handleStatistics,
          },
          logoutButton: {
            text: 'Çıkış Yap',
            click: handleLogout,
          },
        }}
      />
      <LessonModal
        date={selectedDate}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
      {selectedEvent && (
        <>
          <LessonSummaryModal
            event={selectedEvent}
            show={showSummaryModal}
            onClose={() => setShowSummaryModal(false)}
            onEdit={handleEdit}
          />
          <LessonDetailModal
            event={selectedEvent}
            show={showDetailModal}
            onClose={() => setShowDetailModal(false)}
          />
        </>
      )}
    </div>
  );
};

export default Calendar;
