// src/components/LessonModal.js
import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { db } from "../firebaseConfig";

const LessonModal = ({ date, show, onClose }) => {
  const [lessonName, setLessonName] = useState("");
  const [lessonTime, setLessonTime] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Haftalık");

  // Modal her açıldığında formun sıfırlanması için useEffect kullanıyoruz
  useEffect(() => {
    if (show) {
      // Modal açıldığında boş form değerlerini set ediyoruz
      setLessonName("");
      setLessonTime("");
      setHourlyRate("");
      setPaymentMethod("Haftalık");
    }
  }, [show]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newLesson = {
      title: lessonName,
      start: `${date}T${lessonTime}`, // Tarih ve saat formatı uygun olmalı
      extendedProps: {
        hourlyRate,
        paymentMethod,
        paymentStatus: false, // Varsayılan olarak ödeme durumu false
      },
    };

    // Firebase Firestore'a ders ekleme
    db.collection("lessons")
      .add(newLesson)
      .then(() => {
        console.log("Ders başarıyla eklendi:", newLesson);
        onClose(); // Modalı kapat
      })
      .catch((error) => {
        console.error("Ders eklenirken hata oluştu: ", error);
      });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{date} için Yeni Ders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="lessonName">
            <Form.Label>Ders Adı</Form.Label>
            <Form.Control
              type="text"
              value={lessonName}
              onChange={(e) => setLessonName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="lessonTime">
            <Form.Label>Ders Saati</Form.Label>
            <Form.Control
              type="time"
              value={lessonTime}
              onChange={(e) => setLessonTime(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="hourlyRate">
            <Form.Label>Saatlik Ücret</Form.Label>
            <Form.Control
              type="number"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="paymentMethod">
            <Form.Label>Ödeme Şekli</Form.Label>
            <Form.Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            >
              <option value="Haftalık">Haftalık</option>
              <option value="Aylık">Aylık</option>
            </Form.Select>
          </Form.Group>
          <Button variant="primary" type="submit">
            Kaydet
          </Button>
          <Button variant="secondary" onClick={onClose} className="ms-2">
            İptal
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LessonModal;
