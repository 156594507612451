import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { db } from "../firebaseConfig";

const LessonDetailModal = ({ event, show, onClose }) => {
  const [lessonName, setLessonName] = useState("");
  const [lessonTime, setLessonTime] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [lessonProcessed, setLessonProcessed] = useState(false); // Yeni eklenen state
  const [repeatLesson, setRepeatLesson] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = useState('daily');
  const [repeatCount, setRepeatCount] = useState(7);

  // Modal açıldığında seçilen ders bilgilerini state'e yükle
  useEffect(() => {
    if (event && show) {  // show değişikliğinde yeni event bilgilerini yüklüyoruz
      setLessonName(event.title);
      const formattedTime = new Date(event.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      setLessonTime(formattedTime);
      setHourlyRate(event.extendedProps.hourlyRate || "");
      setPaymentStatus(event.extendedProps.paymentStatus || false);
      setLessonProcessed(event.extendedProps.lessonProcessed || false); // Ders işlendi bilgisini yükle
    }
  }, [event, show]);

  // Modal kapatılırken tüm state'leri sıfırla
  const handleClose = () => {
    setLessonName("");
    setLessonTime("");
    setHourlyRate("");
    setPaymentStatus(false);
    setLessonProcessed(false); // Ders işlendi bilgisini sıfırla
    setRepeatLesson(false);
    setRepeatFrequency('daily');
    setRepeatCount(7);
    onClose(); // Üst componentten gelen onClose fonksiyonu
  };

  const handleSave = () => {
    // Firestore'daki dersi güncelle
    db.collection("lessons").doc(event.id).update({
      title: lessonName,
      start: event.start.toISOString().split("T")[0] + "T" + lessonTime,
      "extendedProps.hourlyRate": hourlyRate,
      "extendedProps.paymentStatus": paymentStatus,
      "extendedProps.lessonProcessed": lessonProcessed,  // Ders işlendi bilgisini kaydet
    })
    .then(() => console.log("Ders başarıyla güncellendi"))
    .catch((error) => console.error("Ders güncellenirken hata oluştu:", error));

    // Tekrar dersi ekleme işlemi
    if (repeatLesson) {
      const startDate = new Date(event.start);

      if (repeatFrequency === 'daily') {
        for (let i = 1; i <= repeatCount; i++) {
          const newDate = new Date(startDate);
          newDate.setDate(newDate.getDate() + i); // Günlük ders ekleme
          addLesson(newDate);
        }
      }

      if (repeatFrequency === 'weekly') {
        for (let i = 1; i <= repeatCount; i++) {
          const newDate = new Date(startDate);
          newDate.setDate(newDate.getDate() + i * 7); // Haftalık ders ekleme
          addLesson(newDate);
        }
      }
    }

    handleClose(); // Modalı kapat
  };

  // Yeni ders ekleme fonksiyonu
  const addLesson = (date) => {
    db.collection("lessons").add({
      title: lessonName,
      start: date.toISOString().split("T")[0] + "T" + lessonTime,
      extendedProps: {
        hourlyRate: hourlyRate,
        paymentStatus: paymentStatus,
        lessonProcessed: lessonProcessed,  // Ders işlendi bilgisini ekle
      },
    });
  };

  const handleDelete = () => {
    db.collection("lessons").doc(event.id).delete()
      .then(() => {
        console.log("Ders başarıyla silindi");
        handleClose(); // Modalı kapat
      })
      .catch((error) => console.error("Ders silinirken hata oluştu:", error));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ders Detayları</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Ders Adı */}
          <Form.Group className="mb-3" controlId="lessonName">
            <Form.Label>Ders Adı</Form.Label>
            <Form.Control
              type="text"
              value={lessonName}
              onChange={(e) => setLessonName(e.target.value)}
              required
            />
          </Form.Group>

          {/* Ders Saati */}
          <Form.Group className="mb-3" controlId="lessonTime">
            <Form.Label>Ders Saati</Form.Label>
            <Form.Control
              type="time"
              value={lessonTime}
              onChange={(e) => setLessonTime(e.target.value)}
              required
            />
          </Form.Group>

          {/* Saatlik Ücret */}
          <Form.Group className="mb-3" controlId="hourlyRate">
            <Form.Label>Saatlik Ücret</Form.Label>
            <Form.Control
              type="number"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
              required
            />
          </Form.Group>

          {/* Ödeme Durumu */}
          <Form.Group className="mb-3">
            <Form.Label>Ödeme Durumu</Form.Label>
            <Form.Check
              type="checkbox"
              label="Ödendi"
              checked={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.checked)}
            />
          </Form.Group>

          {/* Ders İşlendi Durumu */}
          <Form.Group className="mb-3">
            <Form.Label>Ders İşlendi</Form.Label>
            <Form.Check
              type="checkbox"
              label="Ders İşlendi"
              checked={lessonProcessed}  // Ders işlendi checkbox
              onChange={(e) => setLessonProcessed(e.target.checked)}
            />
          </Form.Group>

          {/* Ders Tekrar Seçeneği */}
          <Form.Group className="mb-3">
            <Form.Label>Tekrarla</Form.Label>
            <Form.Check
              type="checkbox"
              label="Bu dersi tekrar et"
              checked={repeatLesson}
              onChange={(e) => setRepeatLesson(e.target.checked)}
            />
          </Form.Group>

          {repeatLesson && (
            <>
              {/* Tekrar Frekansı (Günlük / Haftalık) */}
              <Form.Group className="mb-3">
                <Form.Label>Tekrar Frekansı</Form.Label>
                <Form.Control
                  as="select"
                  value={repeatFrequency}
                  onChange={(e) => setRepeatFrequency(e.target.value)}
                >
                  <option value="daily">Günlük</option>
                  <option value="weekly">Haftalık</option>
                </Form.Control>
              </Form.Group>

              {/* Tekrar Sayısı */}
              <Form.Group className="mb-3">
                <Form.Label>Kaç {repeatFrequency === 'daily' ? 'gün' : 'hafta'} tekrar edilsin?</Form.Label>
                <Form.Control
                  type="number"
                  value={repeatCount}
                  onChange={(e) => setRepeatCount(e.target.value)}
                />
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>Ders Sil</Button>
        <Button variant="secondary" onClick={handleClose}>İptal</Button>
        <Button variant="primary" onClick={handleSave}>Kaydet</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonDetailModal;
