import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { Accordion, Button, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const Statistics = () => {
  const [lessons, setLessons] = useState([]);
  const [totalLessons, setTotalLessons] = useState(0);
  const [paidLessons, setPaidLessons] = useState(0);
  const [unpaidLessons, setUnpaidLessons] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [monthlyPayments, setMonthlyPayments] = useState({});
  const [totalPending, setTotalPending] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = db.collection("lessons").onSnapshot((snapshot) => {
      const fetchedLessons = snapshot.docs.map((doc) => doc.data());
      setLessons(fetchedLessons);

      // İstatistik hesaplama
      const total = fetchedLessons.length;
      const paid = fetchedLessons.filter(lesson => lesson.extendedProps.paymentStatus).length;
      const unpaid = total - paid;
      const earnings = fetchedLessons
        .filter(lesson => lesson.extendedProps.paymentStatus)
        .reduce((sum, lesson) => sum + parseFloat(lesson.extendedProps.hourlyRate || 0), 0);

      // Aylık ödemelerin hesaplanması
      const paymentsByMonth = fetchedLessons.reduce((acc, lesson) => {
        if (lesson.extendedProps.paymentStatus) {
          const month = new Date(lesson.start).toLocaleString("tr-TR", { month: "long", year: "numeric" });
          acc[month] = (acc[month] || 0) + parseFloat(lesson.extendedProps.hourlyRate || 0);
        }
        return acc;
      }, {});

      // Bekleyen ödemelerin hesaplanması
      const pendingTotal = fetchedLessons
        .filter(lesson => !lesson.extendedProps.paymentStatus)
        .reduce((sum, lesson) => sum + parseFloat(lesson.extendedProps.hourlyRate || 0), 0);

      setTotalLessons(total);
      setPaidLessons(paid);
      setUnpaidLessons(unpaid);
      setTotalEarnings(earnings);
      setMonthlyPayments(paymentsByMonth);
      setTotalPending(pendingTotal);
    });

    return () => unsubscribe();
  }, []);

  // Dersleri adlarına göre gruplama
  const groupedLessons = lessons.reduce((acc, lesson) => {
    const title = lesson.title;
    if (!acc[title]) {
      acc[title] = [];
    }
    acc[title].push(lesson);
    return acc;
  }, {});

  return (
    <div className="container mt-5" style={{ marginBottom: "50px" }}>
      <h2 className="text-center mb-4">Ders İstatistikleri</h2>

      {/* Takvime Dön Butonu */}
      <div className="text-center mb-4">
      <button className="btn btn-primary mt-4" onClick={() => navigate('/')}>Takvime Dön
      </button>
      </div>

      {/* Genel İstatistik Tablosu */}
      <Table bordered hover className="mb-4">
        <thead className="thead-dark">
          <tr>
            <th>İstatistik</th>
            <th>Değer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Toplam Ders Sayısı</td>
            <td>{totalLessons}</td>
          </tr>
          <tr>
            <td>Ödenen Ders Sayısı</td>
            <td>{paidLessons}</td>
          </tr>
          <tr>
            <td>Ödenmeyen Ders Sayısı</td>
            <td>{unpaidLessons}</td>
          </tr>
          <tr>
            <td>Toplam Kazanç (TL)</td>
            <td>{totalEarnings}</td>
          </tr>
        </tbody>
      </Table>

      {/* Aylık Ödemeler Tablosu */}
      <h3 className="text-center mb-3">Aylık Ödemeler</h3>
      <Table bordered hover className="mb-4">
        <thead className="thead-dark">
          <tr>
            <th>Ay</th>
            <th>Toplam Ödeme (TL)</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(monthlyPayments).map(month => (
            <tr key={month}>
              <td>{month}</td>
              <td>{monthlyPayments[month]}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Aylık Toplam Ödeme</strong></td>
            <td><strong>{Object.values(monthlyPayments).reduce((sum, payment) => sum + payment, 0)}</strong></td>
          </tr>
        </tbody>
      </Table>

      {/* Ders Başına Ödemeler ve Bekleyen Ödemeler (Gruplanmış) */}
      <h3 className="text-center mb-3">Derslere Göre Ödemeler</h3>
      <Accordion>
        {Object.keys(groupedLessons).map((lessonTitle, index) => {
          const paidLessons = groupedLessons[lessonTitle]
            .filter(lesson => lesson.extendedProps.paymentStatus)
            .sort((a, b) => new Date(a.start) - new Date(b.start));  // Ödenenleri tarihe göre sırala

          const unpaidLessons = groupedLessons[lessonTitle]
            .filter(lesson => !lesson.extendedProps.paymentStatus)
            .sort((a, b) => new Date(a.start) - new Date(b.start));  // Bekleyenleri tarihe göre sırala

          const totalPaid = paidLessons.reduce((sum, lesson) => sum + parseFloat(lesson.extendedProps.hourlyRate || 0), 0);
          const totalPending = unpaidLessons.reduce((sum, lesson) => sum + parseFloat(lesson.extendedProps.hourlyRate || 0), 0);

          return (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{lessonTitle}</Accordion.Header>
              <Accordion.Body>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Tarih</th>
                      <th>Ödenen Miktar (TL)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paidLessons.map((lesson, idx) => (
                      <tr key={idx}>
                        <td>{new Date(lesson.start).toLocaleDateString("tr-TR")}</td>
                        <td>{lesson.extendedProps.hourlyRate}</td>
                      </tr>
                    ))}
                    <tr>
                      <td><strong>Toplam Ödenen</strong></td>
                      <td><strong>{totalPaid}</strong></td>
                    </tr>
                  </tbody>
                </Table>

                <h5 className="mt-3">Bekleyen Ödemeler</h5>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Tarih</th>
                      <th>Bekleyen Miktar (TL)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unpaidLessons.map((lesson, idx) => (
                      <tr key={idx}>
                        <td>{new Date(lesson.start).toLocaleDateString("tr-TR")}</td>
                        <td>{lesson.extendedProps.hourlyRate}</td>
                      </tr>
                    ))}
                    <tr>
                      <td><strong>Toplam Bekleyen</strong></td>
                      <td><strong>{totalPending}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>

    </div>
  );
};

export default Statistics;
