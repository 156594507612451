// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // AuthContext'ten oturum durumunu çekiyoruz

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth(); // Giriş yapan kullanıcı bilgisi

  return currentUser ? children : <Navigate to="/login" />; // Kullanıcı yoksa login sayfasına yönlendir
};

export default PrivateRoute;
