// src/firebaseConfig.js
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Firebase yapılandırması
const firebaseConfig = {
  apiKey: "AIzaSyBjmrp0Ul7RSoEUJRubyAA82i6wsySljFk",
  authDomain: "ders-takip-1db23.firebaseapp.com",
  projectId: "ders-takip-1db23",
  storageBucket: "ders-takip-1db23.appspot.com",
  messagingSenderId: "574936482703",
  appId: "1:574936482703:web:fdd62d43cab6ea653d81fa"
};

// Firebase'i başlat
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export default firebase;
