// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Calendar from "./components/Calendar";
import Statistics from "./components/Statistics";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute"; // Özel rota
import { AuthProvider } from "./context/AuthContext"; // AuthContext sağlayıcısı

function App() {
  return (
    <Router>
      <AuthProvider> {/* Tüm uygulama genelinde oturum durumunu sağlayacak */}
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Yalnızca giriş yapılmış kullanıcılar Calendar ve Statistics sayfasına erişebilir */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Calendar />
              </PrivateRoute>
            }
          />
          <Route
            path="/statistics"
            element={
              <PrivateRoute>
                <Statistics />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
