import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { db } from "../firebaseConfig";

const LessonSummaryModal = ({ event, show, onClose, onEdit }) => {
  const [paymentStatus, setPaymentStatus] = useState(event.extendedProps.paymentStatus || false);
  const [lessonProcessed, setLessonProcessed] = useState(event.extendedProps.lessonProcessed || false); // Ders işlendi durumu

  // Yeni ders geldiğinde state'i güncelle
  useEffect(() => {
    if (event) {
      setPaymentStatus(event.extendedProps.paymentStatus || false);
      setLessonProcessed(event.extendedProps.lessonProcessed || false); // Ders işlendi durumu güncellenir
    }
  }, [event]);

  const handleMarkAsPaid = () => {
    // Firestore'daki ödeme durumunu güncelle
    db.collection("lessons").doc(event.id).update({
      "extendedProps.paymentStatus": !paymentStatus,
    })
    .then(() => console.log("Ödeme durumu başarıyla güncellendi"))
    .catch((error) => console.error("Ödeme durumu güncellenirken hata oluştu:", error));

    setPaymentStatus(!paymentStatus); // Ekranı güncelle
    onClose(); // Modalı kapat
  };

  const handleMarkAsProcessed = () => {
    // Firestore'daki ders işlendi durumunu güncelle
    db.collection("lessons").doc(event.id).update({
      "extendedProps.lessonProcessed": !lessonProcessed,
    })
    .then(() => console.log("Ders işlendi durumu başarıyla güncellendi"))
    .catch((error) => console.error("Ders işlendi durumu güncellenirken hata oluştu:", error));

    setLessonProcessed(!lessonProcessed); // Ekranı güncelle
    onClose(); // Modalı kapat
  };

  const handleDelete = () => {
    db.collection("lessons").doc(event.id).delete()
      .then(() => {
        console.log("Ders başarıyla silindi");
        onClose();
      })
      .catch((error) => console.error("Ders silinirken hata oluştu:", error));
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{event.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ders Saati: {new Date(event.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
        <p>Ödeme Durumu: {paymentStatus ? "Ödendi" : "Ödenmedi"}</p>
        <p>Ders Durumu: {lessonProcessed ? "İşlendi" : "İşlenmedi"}</p> {/* Ders işlendi durumu */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleMarkAsPaid}>
          {paymentStatus ? "Ödemeyi Geri Al" : "Ödendi Olarak İşaretle"}
        </Button>
        <Button variant="warning" onClick={handleMarkAsProcessed}> {/* Ders işlendi butonu */}
          {lessonProcessed ? "Dersi Geri Al" : "Ders İşlendi Olarak İşaretle"}
        </Button>
        <Button variant="primary" onClick={onEdit}>Düzenle</Button>
        <Button variant="danger" onClick={handleDelete}>Ders Sil</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonSummaryModal;
