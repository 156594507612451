// src/components/Login.js
import React, { useState } from "react";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom"; // react-router-dom kullanılacak

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        navigate("/"); // Giriş başarılıysa ana sayfaya yönlendir
      })
      .catch((error) => {
        alert("Giriş başarısız: " + error.message);
      });
  };

  const handleSignup = () => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        alert("Kayıt başarılı. Giriş yapabilirsiniz.");
      })
      .catch((error) => {
        alert("Kayıt başarısız: " + error.message);
      });
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Kullanıcı Girişi</h2>
      <form onSubmit={handleLogin}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">E-posta</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Şifre</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Giriş Yap</button>
        <button type="button" className="btn btn-secondary" onClick={handleSignup}>
          Kayıt Ol
        </button>
      </form>
    </div>
  );
};

export default Login;
